import React from "react";

function Footer() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 footer">
          <p>&copy;Saurav Sanjay</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
